
import Vue from 'vue';
import { nanoid } from 'nanoid';

import Icon, { IconName } from 'UI/Icon.vue';

interface Props {
    value: boolean;
    disabled?: boolean;
    withIcon?: IconName;
    offIcon?: IconName;
    withText?: string;
    offText?: string;
    isRed?: boolean;
    needEmitEvent?: boolean;
}

interface Data {
    id: string;
}

interface Methods {
    onChange: (e: InputEvent) => void;
}

type NoData = Record<string, never>;

export default Vue.extend<Data, Methods, NoData, Props>({
    name: 'SwitchControl',
    components: {
        Icon,
    },
    data() {
        return {
            id: nanoid(7),
        };
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        withIcon: {},
        offIcon: {},
        withText: {
            type: String,
            default: '',
        },
        offText: {
            type: String,
            default: '',
        },
        isRed: {
            type: Boolean,
            default: false,
        },
        needEmitEvent: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onChange(e) {
            this.$emit('change', this.needEmitEvent ? e.target : (e.target as HTMLInputElement).checked);
        },
    },
});
