
import Vue from 'vue';

/*
* emits
* change: () => number;
* */

interface Props {
    firstVariantText: string;
    secondVariantText: string;
    activeIndex: number;
}

interface Data {
    activeElementIndex: number;
}

interface Methods {
    onChange: (data: number) => void;
}

interface Computed {
    elementsArray: string[];
}

export default Vue.extend<Data, Methods, Computed, Props>({
    props: {
        firstVariantText: {
            type: String,
            required: true,
        },
        secondVariantText: {
            type: String,
            required: true,
        },
        activeIndex: {
            type: Number,
            required: true,
        },
    },
    computed: {
        elementsArray() {
            return [this.firstVariantText, this.secondVariantText];
        },
    },
    methods: {
        onChange(index) {
            this.$emit('change', index);
        },
    },
});
