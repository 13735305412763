
import Vue from 'vue';

import Icon from 'UI/Icon.vue';

type Empty = Record<string, never>;

interface Props {
    text: string;
}

interface Data {
    open: boolean;
}

export default Vue.extend<Data, Empty, Empty, Props>({
    components: {
        Icon,
    },
    data() {
        return { open: false };
    },
    props: {
        text: {
            type: String,
        },
    },
});
