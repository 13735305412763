
import Vue from 'vue';

import Icon from 'UI/Icon.vue';
import { composedPath } from 'Lib/utils/eventPathChecker';

/*
* emit
* change: () => { item: string; index: number; };
* */

interface Props {
    items?: string[];
    isAlignedLeft?: boolean;
    disabled?: boolean;
    isBlue?: boolean;
    isLink?: boolean;
    isBlockchain?: boolean;
    isPlacement?: boolean;
    activeIndex: number;
}

interface Data {
    isListShown: boolean;
    startIndexChanged: boolean;
}

interface Methods {
    selectItem: (data: number) => void;
    toggleItemsList: () => void;
}

interface Computed {
    activeItem: string;
}

export default Vue.extend<Data, Methods, Computed, Props>({
    components: { Icon },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        isAlignedLeft: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        isBlue: {
            type: Boolean,
            default: false,
        },
        isLink: {
            type: Boolean,
            default: false,
        },
        activeIndex: {
            type: Number,
            required: true,
        },
        isBlockchain: {
            type: Boolean,
            default: false,
        },
        isPlacement: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isListShown: false,
            startIndexChanged: false,
        };
    },
    computed: {
        activeItem() {
            if (!this.items || this.items.length === 0) {
                return 'No Items';
            }
            return this.items[this.activeIndex];
        },
    },
    methods: {
        selectItem(index) {
            this.isListShown = false;
            this.$emit('change', { item: this.items![index], index });
        },
        toggleItemsList() {
            if (this.disabled) {
                return;
            }
            this.isListShown = !this.isListShown;
        },
    },
    mounted() {
        document.addEventListener('click', (event) => {
            const path = composedPath(event.target);
            if (!path.some((el) => el.classList && el.classList.contains('dropdown-container'))) {
                this.isListShown = false;
            }
        });
    },
});
