// This file was autogenerated. Please do not change.
export interface IDeleteRequisitesRequestData {
    alias: string;
    totp: string;
}

export default class DeleteRequisitesRequestData {
    readonly _alias: string;

    /** Description: Requisites alias */
    get alias(): string {
        return this._alias;
    }

    readonly _totp: string;

    /** Description: TOTP verification code */
    get totp(): string {
        return this._totp;
    }

    constructor(props: IDeleteRequisitesRequestData) {
        this._alias = props.alias.trim();
        this._totp = props.totp.trim();
    }

    serialize(): IDeleteRequisitesRequestData {
        const data: IDeleteRequisitesRequestData = {
            alias: this._alias,
            totp: this._totp,
        };
        return data;
    }

    toJSON(): IDeleteRequisitesRequestData {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            alias: typeof this._alias === 'string' && !!this._alias.trim(),
            totp: typeof this._totp === 'string' && !!this._totp.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
