
import Vue from 'vue';
import { mapGetters } from 'vuex';

import Faq from 'UI/Faq.vue';
import Search from 'Control/Search.vue';
import Switch from 'Control/Switch.vue';
import Dropdown from 'Control/Dropdown.vue';
import AccountColorMarker from 'Common/AccountColorMarker.vue';
import Account from 'Lib/entities/privatePresenter/Account';

interface Methods {
    onSwitch: (e: boolean) => void;
    onSelect: ({ item: string, index: number }) => void;
    onSearch: (e: string) => void;
}

type NoData = Record<string, never>;

/** Emit
 * on-switch: (value: boolean) => void;
 * on-search: (value: string) => void;
 * on-select: (value: {item: string, index: number}) => void;
 */
interface Props {
    title: string;
    faq?: string;
    withSwitch?: boolean;
    withSearch?: boolean;
    withSelect?: boolean;
    selectOption?: string[];
    selectActiveIndex?: number;
    isSelectDisabled?: boolean;
    isSlotAlignedLeft?: boolean;
}

interface Computed {
    activeAccount: Account;
    isThemeDark: boolean;
    activeAccountColor: string;
}

export default Vue.extend<NoData, Methods, Computed, Props>({
    components: {
        Search,
        Switch,
        Faq,
        Dropdown,
        AccountColorMarker,
    },
    props: {
        title: {
            default: '',
        },
        faq: {
            default: '',
        },
        withSwitch: {
            default: false,
        },
        withSearch: {
            default: false,
        },
        withSelect: {
            default: false,
        },
        selectOption: {
            default: undefined,
        },
        selectActiveIndex: {
            default: undefined,
        },
        isSelectDisabled: {
            type: Boolean,
            default: false,
        },
        isSlotAlignedLeft: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            activeAccount: 'Accounts/activeAccount',
            isThemeDark: 'isThemeDark',
        }),
        activeAccountColor() {
            if (!this.activeAccount || !this.activeAccount.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return this.activeAccount.color;
        },
    },
    methods: {
        onSwitch(e) {
            this.$emit('on-switch', e);
        },
        onSearch(e) {
            this.$emit('on-search', e);
        },
        onSelect(e) {
            this.$emit('on-select', e);
        },
    },

});
